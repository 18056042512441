<template>
  <dashboard-layout>
    <template slot="heading">
      <v-toolbar-title>Manage Machine</v-toolbar-title>
    </template>
    <template slot="content">
      <loading-circular v-if="loading"></loading-circular>
      <v-alert rounded="0" type="error" v-if="!loading && error">{{ error }}</v-alert>

      <v-container v-if="!loading && !error">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title v-text="'WiFi Hotspot Machine'"></v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>

                      <v-chip class="my-2" label>
                        {{ machine.cid }}
                      </v-chip>

                      <v-btn
                        elevation="1"
                        small
                        color="primary"
                        class="my-2"
                        v-clipboard="machine.cid"
                      >
                        Copy Device ID
                      </v-btn>

                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="!machine.product_key">
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        License:
                        <span class="red--text">Not Activated</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="machine.product_key">
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        License:
                        <router-link :to="{name: 'License', params: {id: machine.product_key.id}}">
                          {{machine.product_key.code}}
                        </router-link>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Status:
                        <span :class="{'green--text': machine.online, 'red--text': !machine.online}">
                          {{ machine.online? 'Online' : 'Offline' }}
                        </span>
                      </v-list-item-subtitle>

                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        Software: {{machine.software_version}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                </v-list>

              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="machine.product_key">
            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>Manage</v-card-title>
                  <v-card-text>
                    <p v-if="machine.ngrok_url">
                      Permanent Remote URL: <a target="_blank" :href="machine.remote_url">{{machine.remote_url }}</a>
                    </p>
                    <v-chip v-if="!machine.ngrok_url">
                      Permanent Remote URL: {{machine.remote_url}}
                    </v-chip>
                    <v-btn
                      v-external-link
                      class="ma-2"
                      color="success"
                      :url="machine.ngrok_url"
                      v-if="machine.ngrok_url"
                    >Remote</v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" v-if="machine.product_key || machine.pending_revoke_token">
            <v-card>
              <v-card-title>Revoke License</v-card-title>
              <v-card-subtitle>Remove the license form current machine so you can transfer it to other machine.</v-card-subtitle>
              <v-card-subtitle>
                Before you proceed, learn more about <a v-external-link href="https://www.adopisoft.com/blog/articles/revoking-license">REVOKING LICENSE</a>.
              </v-card-subtitle>
              <v-card-text>
                <v-btn color="red" @click="revokeLicense" v-if="!machine.pending_revoke_token" :loading="revoking" :disabled="revoking">
                  Revoke License
                </v-btn>
                <template v-if="machine.pending_revoke_token">
                  <v-form v-model="tokenValid" @submit.prevent="confirmRevoke(machine.pending_revoke_token.id, revoke_token)">
                    <v-text-field
                      label="Confirmation Token"
                      v-model="revoke_token"
                      :rules="revokeTokenRules"
                      :error-messages="revokeTokenErrors"
                      :loading="revoking"
                      :disabled="revoking"
                      required
                      outlined
                    ></v-text-field>

                    <template v-if="!revoking">
                      <v-btn
                        color="red"
                        type="submit"
                        class="ma-1"
                        :loading="revoking"
                        :disabled="!tokenValid"
                      >
                        Confirm Revoke Request
                      </v-btn>

                      <v-btn
                        @click="cancelRevoke"
                        class="ma-1"
                      >
                        Cancel Revoke Request
                      </v-btn>

                      <p v-if="machine.wait_for" class="my-2 blue--text">The license will be revoked automatically after <b>{{machine.wait_for}}</b> if not confirmed.</p>

                    </template>

                  </v-form>
                </template>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-title>
                Alias & Description
              </v-card-title>
              <v-card-text>
                <v-form @submit.prevent="updateMachine">
                  <v-text-field
                    v-model="machine.alias"
                    label="Alias"
                  ></v-text-field>

                  <v-textarea
                    v-model="machine.description"
                    label="Description"
                  ></v-textarea>

                  <v-btn
                    :loading="updating"
                    :disabled="updating"
                    type="submit"
                    color="primary"
                  >
                    Save
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-container>

    </template>
  </dashboard-layout>
</template>

<script>
  import { getMachine, updateMachine, revokeLicense, cancelRevokeLicense, confirmRevokeLicense } from '@/services/http/machines'

  export default {
    name: 'Machine',
    data() {
      return {
        loading: true,
        updating: false,
        revoking: false,
        error: null,
        machine: null,

        tokenValid: true,
        revoke_token: '',
        revokeTokenRules: [
          t => !!t || 'Required'
        ],
        revokeTokenErrors: []
      }
    },
    created() {
      this.load()
    },
    updated() {
    },
    methods: {
      async load() {
        try {
          const id = this.$route.params.id
          this.machine = await getMachine(id)
          this.loading = false
        } catch(e) {
          this.error = e
          this.loading = false
        }
      },
      async updateMachine() {
        this.updating = true
        try {
          await updateMachine(this.$route.params.id, this.machine)
          this.load()
          this.$toast('Machine updated successfully.')
        } catch(e) {
          this.$toast(e.toString())
        }
        this.updating = false
      },
      async revokeLicense() {
        try {
          const title = 'Confirm Revoke License'
          const yn = await this.$confirm('The machine will no longer work if the license is revoked.', { title })
          if (yn) {
            this.revoking = true
            this.machine = await revokeLicense(this.machine.id)
            this.$toast('Revoke request successfully created.')
          }
        } catch(e) {
          this.$toast(e.toString())
        }
        this.revoking = false
      },
      async cancelRevoke() {
        this.revoking = true
        try {
          await cancelRevokeLicense(this.machine.id)
          this.$toast('Revoke request cancelled successfully.')
          this.load()
        } catch(e) {
          this.$toast(e.toString())
        }
        this.revoking = false
      },
      async confirmRevoke(id, revoke_token) {
        try {
          const title = 'Confirm Revoke License'
          const yn = await this.$confirm('The machine will no longer work if the license is revoked.', { title })
          if (yn) {
            this.revoking = true
            this.revokeTokenErrors = []
            await confirmRevokeLicense(id, revoke_token)
            await this.$toast('Revoke request confirmed successfully.')
            this.$router.push({name: 'Machines'})
          }
        } catch(e) {
          this.revokeTokenErrors = [e.toString()]
        }
        this.revoking = false
      },
    },
    watch: {
      revoke_token(token) {
        if (token)
          this.revokeTokenErrors = []
      }
    }
  }
</script>
